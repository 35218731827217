import React from "react";
import styles from "./Modal.module.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUpgradeTeaser } from "@comp/General/General.duck.js";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
library.add(faTimes);

// TODO: close when pressing esc key?
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.close();
    }
  };

  close() {
    this.props.setUpgradeTeaser(false);
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <div
        onClick={() => {
          this.close();
        }}
        className={styles.wrapper}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={classNames(styles.modal, this.props.className)}>
          <div
            title="close"
            className={styles.close}
            onClick={() => {
              this.close();
            }}>
            <FontAwesomeIcon icon="times" size="2x" />
          </div>
          <div className={styles.contentWrapper}>
            <div
              className={classNames(styles.content, this.props.contentClass)}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUpgradeTeaser }, dispatch);

export default connect(undefined, mapDispatchToProps)(Modal);
