import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon, Input } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { debugUpdate } from "@utils/debug";
import { LogoSvg } from "@graphics/svgs";
import ProfileImage from "@common/ProfileImage/ProfileImage.js";
import Button from "@common/Button/Button.js";
import { getProfileImage } from "@comp/Profile/Profile.duck.js";
import UploadSmallSvg from "@styles/icons/upload2-svg.js";
import PersonSvg from "@styles/icons/person-svg.js";
import CloseSvg from "@styles/icons/close-svg.js";
import env from "@env";
import config from "@config";

// import "./Topbar.scss";
import styles from "./Topbar.module.scss";

import { push } from "connected-react-router";
import MobileMenuContainer from "@comp/General/MobileMenuContainer/MobileMenuContainer.js";

const { Search } = Input;

const LinkInline = styled(Link)`
  display: inline-block !important;
`;

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      username: null,
      mobileMenuIsOpen: false,
    };
    this.header = null;
    this.showHeader = true;
  }

  isViewer = (pathname) => pathname.match(/^\/viewer\/.*/);

  componentDidMount() {
    if (this.isViewer(this.props.history.location.pathname)) {
      this.setState({ isViewer: true });
    }
    this.props.history.listen((location) => {
      if (this.isViewer(location.pathname)) {
        this.setState({ isViewer: true });
      } else {
        this.setState({ isViewer: false });
      }
    });
    this.header = document.getElementById("header");
    if (this.props.mouseShow) {
      document.addEventListener("mousemove", _.debounce(this.mouseMove, 10));
      setTimeout(() => {
        const topbar = document.getElementById("topbar");
        topbar.classList.add("afterSetup");
      }, 4000);
    }
  }

  componentWillUnmount() {
    // this.unlisten();
  }

  static getDerivedStateFromProps(props, state) {
    let viewer =
      props.viewer.document && state.isViewer
        ? props.viewer.document.viewer_info
        : null;

    let user = props.viewer.document ? props.viewer.document.user : null;
    if (props.auth.jwt) {
      return {
        loggedIn: true,
        username: props.auth.user.username,
        viewer,
        user,
      };
    } else {
      return {
        loggedIn: false,
        username: null,
        viewer,
        user,
      };
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // debugUpdate("Topbar", this.props, prevProps, this.state, prevState);
  }

  mouseMove = (e) => {
    if (this.showHeader) {
      this.showHeader = false;
      this.header.style.opacity = 1;
      const elems = document.getElementsByClassName("mouseShow");
      Array.prototype.forEach.call(elems, function (el) {
        el.style.opacity = 1;
      });

      setTimeout(() => {
        const searchInput = document.getElementById("searchInput");
        this.header.style.opacity = 0;
        Array.prototype.forEach.call(elems, function (el) {
          el.style.opacity = 0;
        });
        this.showHeader = true;
      }, 4000);
    }
  };

  search = (_value) => {
    console.log("_value", _value);
    this.props.goToSearch(_value);
  };

  upload = (_e) => {
    if (this.props.auth.jwt) {
    } else {
    }
  };

  render() {

    const { username, loggedIn } = this.state;

    let _style = {
      display: "inline-block"
    };

    if (this.state.viewer) {
      _style = {
        display: "inline-block",
        backgroundImage: `url(${env.REACT_APP_API_URL}/users/${this.state.user.username}/logo/normal?)`

      };
    }

    return (
      <>
        {this.state.loggedIn ? (<div
          id="topbar"
          style={{
            background: this.state.viewer
              ? this.state.viewer.topbar_color
              : "#D33F49",
            paddingRight:
              window.innerWidth >= config.responsive.tablet ? "60px" : "10px",
            paddingLeft:
              window.innerWidth >= config.responsive.tablet ? "60px" : "10px",
          }}
          className={styles.topbar}>
          <div className={`${styles.item} ${styles.topbarLogo}`}>
            <a
              style={_style}
              target={this.state.isViewer ? "_blank" : ""}
              href={
                this.state.viewer
                  ? this.state.viewer.link
                    ? this.state.viewer.link
                    : "/"
                  : "/"
              }></a>
          </div>

          {window.innerWidth >= config.responsive.mobil ? (
            <>
              <div className={`${styles.item} ${styles.upload}`}>
                <Link to="/documents/add">
                  <Button onClick={this.upload} type="secondary">
                    + Upload now
                  </Button>
                </Link>
              </div>

              <div className={`${styles.item} ${styles.search}`}>
                <Search
                  onSearch={this.search}
                  onPressEnter={(e) => this.search(e.target.value)}
                  style={{ maxWidth: "900px", minWidth: "210px" }}
                />
              </div>

              <div className={`${styles.item} ${styles.menuBtn}`}>
                <Icon type="logout" /> &nbsp;&nbsp;
                <LinkInline to="/logout">
                  <span className="white">Logout</span>
                </LinkInline>
              </div>
            </>
          ) : (
              <>
                <div
                  onClick={() => {
                    this.setState({
                      mobileMenuIsOpen: this.state.mobileMenuIsOpen
                        ? false
                        : true,
                    });
                  }}
                  className={`${styles.item} ${styles.menuBtn}`}>
                  {this.state.mobileMenuIsOpen ? (
                    <CloseSvg />
                  ) : (
                      <Icon type="menu" style={{ padding: "0", margin: "0" }} />
                    )}
                </div>

                <div className={`${styles.item} ${styles.upload}`}>
                  <Link to="/documents/add">
                    <UploadSmallSvg style={{ fill: "#fff", stroke: "#fff" }} />
                  </Link>
                </div>

                <div className={`${styles.item} ${styles.search}`}>
                  <Icon
                    type="search"
                    style={{ padding: "0 15px", margin: "0" }}
                  />
                </div>
              </>
            )}

          <div className={`${styles.item} ${styles.profileImg}`}>
            <LinkInline to={`/users/${this.state.username}`}>
              <ProfileImage src={getProfileImage(this.state.username)} />
            </LinkInline>
          </div>
        </div>) : (<div
          id="topbar"
          style={{
            background: this.state.viewer
              ? this.state.viewer.topbar_color
              : "#D33F49",
            paddingRight:
              window.innerWidth >= config.responsive.tablet ? "60px" : "10px",
            paddingLeft:
              window.innerWidth >= config.responsive.tablet ? "60px" : "10px",
          }}
          className={styles.topbar}>
          <div className={`${styles.item} ${styles.topbarLogo}`}>
            <a
              style={_style}
              target={this.state.isViewer ? "_blank" : ""}
              href={
                this.state.viewer
                  ? this.state.viewer.link
                    ? this.state.viewer.link
                    : "/"
                  : "/"
              }></a>
          </div>
          {window.innerWidth >= config.responsive.mobil ? (
            <>
              <div className={`${styles.item} ${styles.menuBtn}`}>
                <Icon type="login" /> &nbsp;&nbsp;
                <LinkInline to="/auth">
                  <span className="white">Login / Sign Up</span>
                </LinkInline>
              </div>

              <div className={`${styles.item} ${styles.upload}`}>
                {/* 
                <Link to="/documents/add">
                  <Button onClick={this.upload} type="secondary">
                    + Upload now
                  </Button>
                </Link>
                */}
              </div>

              <div className={`${styles.item} ${styles.search}`}>
                <Search
                  onSearch={this.search}
                  onPressEnter={(e) => this.search(e.target.value)}
                  style={{ maxWidth: "900px", minWidth: "210px" }}
                />
              </div>
            </>
          ) : (
              <>
                <div
                  onClick={() => {
                    this.setState({
                      mobileMenuIsOpen: this.state.mobileMenuIsOpen
                        ? false
                        : true,
                    });
                  }}
                  className={`${styles.item} ${styles.menuBtn}`}>
                  <Icon type="menu" style={{ padding: "0", margin: "0" }} />
                </div>

                <div className={`${styles.item} ${styles.upload}`}>
                  {/*
                <Link to="/documents/add">
                  <UploadSmallSvg style={{ fill: "#fff", stroke: "#fff" }} />
                </Link>
                */}
                </div>

                <div className={`${styles.item} ${styles.search}`}>
                  <Icon style={{ padding: "0 15px", margin: "0" }} />
                </div>
                <div className={`${styles.item} ${styles.profileImg}`}>
                  <LinkInline to="/login">
                    <PersonSvg />
                  </LinkInline>
                </div>
              </>
            )}
        </div>)}

        {this.state.mobileMenuIsOpen ? (
          <MobileMenuContainer
            onClick={() => this.setState({ mobileMenuIsOpen: false })}
            username={username}
            loggedIn={loggedIn}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  viewer: state.viewer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToSearch: (_term) => push(`/search/${_term}`),
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
