import React from "react";

export default (props) => {
  let style = {
    // set defaults
    fill: "none",
    stroke: "#fff",
    width: "13.965",
    height: "13.965",
    ...props.style, // allow overriding defaults
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      viewBox="0 0 13.965 13.965">
      <g transform="translate(-1215.959 -1426.499) rotate(45)">
        <line className="a" y2="18.75" transform="translate(1878.375 139.5)" />
        <line
          className="a"
          y2="18.75"
          transform="translate(1887.75 148.875) rotate(90)"
        />
      </g>
    </svg>
  );
};
