import axios from "axios";
import env from "@env";
import Call from "@utils/Call.js";
import api from "@api";
import store from "@store/store";

const AUTH_LOADING = "AUTH_LOADING";
const AUTH_SERVER_ERROR = "AUTH_SERVER_ERROR";
const AUTH_DONE = "AUTH_DONE";
const LOGIN_ERROR = "LOGIN_ERROR";
const SIGNUP_ERROR = "SIGNUP_ERROR";

const SIGNUP = "SIGNUP";
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const VERIFY = "VERIFY";

const SET_USER = "SET_USER";
const SET_FEATURES = "SET_FEATURES";
const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
const FEATURES = "FEATURES";
const SETUSER = "SETUSER";

const initialState = {
  server_error: false,
  loading: false,
  action: null,
  data: null,
  jwt: null,
  user: null,
  msg: null,
};

// REDUCERS --- : Handle the result of the actions
export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
        server_error: false,
        action: action._action, // Signup, Login, etc
      };
    case SIGNUP:
      return {
        ...state,
        loading: false,
        server_error: false,
      };
    case LOGIN:
      return {
        ...state,
        loading: false,
        server_error: false,
        jwt: action.data.token,
        user: action.data.user,
      };
    case SET_USER:
      return {
        ...state,
        loading: false,
        user: action.response.data,
      };
    case SET_FEATURES:
      let newState = { ...state };
      newState.user.active_features = action.data;
      return newState;
    case LOGOUT:
      return {
        ...initialState,
      };

    case AUTH_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        server_error: true,
      };
    case AUTH_DONE:
      return {
        ...state,
        loading: false,
        server_error: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        server_error: true,
        msg: action.msg,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        server_error: true,
        msg: action.msg,
      };
    case SETUSER:
      return {
        ...state,
        user: action.user,
        jwt: action.token,
      };
    case UPDATE_USER_SETTINGS:
      let updated = state.user;
      Object.keys(action.user).map((prop) => {
        updated[prop] = action.user[prop];
      });
      return {
        ...state,
        user: updated,
      };
    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

function actionLoading(_action) {
  return { type: AUTH_LOADING, _action };
}

function serverError() {
  return { type: AUTH_SERVER_ERROR, response: { success: false } };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function signup(_data) {
  return (dispatch) => {
    return (
      fetch(`${env.REACT_APP_API_URL}/users`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(_data), // body data type must match "Content-Type" header
      })
        // .then(handleErrors)
        .then((response) => response.json())
        .then((myJson) => dispatch({ type: SIGNUP, data: myJson }))
      // .catch((error) => {
      // console.error("Problem with your fetch operation:", error.toString());
      //   dispatch({ type: SIGNUP_ERROR, msg: "signup err" });
      // })
    );
  };
}

// export function deleteAccount(_data) {
//   return dispatch => {
//     fetch(`${env.REACT_APP_API_URL}/users`, {
//       method: 'DELETE',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(_data) // body data type must match "Content-Type" header
//     })
//       .then(handleErrors)
//       .then(response => response.json())
//       .then(
//         // myJson => dispatch({type: DELETE_ACCOUNT, data: myJson })
//       )
//     .catch((error) => {
//       console.error(error);
//     });
//   }
// }

export function getFeatures() {
  return async (dispatch) => {
    const res = await api.Subscription.getFeatures(store.getState().auth.jwt);
    const active_features = await res.json();
    console.log(active_features);
    dispatch({ type: SET_FEATURES, data: active_features });
  };
}

export function login(_data) {
  return (dispatch) => {
    fetch(`${env.REACT_APP_API_URL}/users/sign_in`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(_data), // body data type must match "Content-Type" header
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then(async (myJson) => {
        const res = await api.Subscription.getFeatures(myJson.token).then(
          handleErrors,
        );
        const active_features = await res.json();
        myJson.user.active_features = active_features;
        return myJson;
      })
      .then((myJson) => {
        window.localStorage.setItem("apiToken", myJson.token);
        dispatch({ type: LOGIN, data: myJson });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error.toString());
        dispatch({ type: LOGIN_ERROR, msg: "Wrong username or password" });
      });
  };
}

export function saveUserSettings(_token, _user) {

  return (dispatch) => {
    dispatch(actionLoading());
    return axios({
      method: "PATCH",
      url: `${env.REACT_APP_API_URL}/users/${_user.username}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${_token}`,
      },
      data: {
        user: _user,
      },
      json: true,
    })
      .then((response) => {

        if (response.status === 200) {
          return dispatch({ type: UPDATE_USER_SETTINGS, user: _user });
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function savePassword(_token, _values) {

  return (dispatch) => {
    dispatch(actionLoading());
    return axios({
      method: "PATCH",
      url: `${env.REACT_APP_API_URL}/users`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${_token}`,
      },
      data: {
        user: _values,
      },
      json: true,
    })
      .then((response) => {

        console.log("response", response);

        if (response.status === 200) {
          return dispatch({ type: UPDATE_USER_SETTINGS, user: {} });
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export async function changePassword(
  current_password,
  password,
  password_confirmation,
) {
  const res = await Call.patch(`${env.REACT_APP_API_URL}/users`, {
    user: {
      current_password,
      password,
      password_confirmation,
    },
  });
  return res;
}

export async function forgotPassword(email) {
  const res = await Call.post(`${env.REACT_APP_API_URL}/users/password`, {
    user: { email },
  });
  return res;
}

export function logout() {
  return { type: LOGOUT };
}

export function setUser(user, token) {
  return {
    type: SETUSER,
    user,
    token,
  };
}
