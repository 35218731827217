import axios from "axios";
import uuid from "@utils/uuid";
import env from "@env";
import { CALL_HISTORY_METHOD } from "connected-react-router";

// Initial

const EDITOR_ID = "EDITOR_ID";

const EDITOR_LOADING = "EDITOR_LOADING";
const EDITOR_SERVER_ERROR = "EDITOR_SERVER_ERROR";
const EDITOR_DONE = "EDITOR_DONE";

// Elements

const RM_DOM_ELEMENT = "RM_DOM_ELEMENT";
const RM_DB_ELEMENT = "RM_DB_ELEMENT";
const SET_ELEMENTS = "SET_ELEMENTS";

// Widgets
const PLACE_WIDGET = "PLACE_WIDGET";
const GET_WIDGETS = "GET_WIDGETS";
const CLEAN_WIDGETS = "CLEAN_WIDGETS";

// Elements Editor Function

const SELECT = "SELECT";
const SET_WIDGET_CONTENT = "SET_WIDGET_CONTENT";
const SET_ELEMENT_PROPS = "SET_ELEMENT_PROPS";

// Interface

const SET_PANEL = "SET_PANEL";
const SET_BOARD = "SET_BOARD";

const initialState = {
  id: null,
  panel: null,
  board: null,
  server_error: false,
  loading: false,
  selected: null,
  contents: [],
  widgets: [],
};

// REDUCERS --- : Handle the result of the actions
let _element;
let _contents;

let _widgets;
let _widget;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PANEL:
      return {
        ...state,
        panel: action._panel,
      };

    case SET_BOARD:
      return {
        ...state,
        board: action._board,
      };

    case EDITOR_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        server_error: true,
      };

    case SELECT:
      return {
        ...state,
        selected: action._uuid,
      };

    case EDITOR_ID:
      return {
        ...state,
        id: action._id,
      };

    case SET_WIDGET_CONTENT:
      _widgets = state.widgets;
      for (let i = 0; i < _widgets.length; i++) {
        if (_widgets[i].uuid === state.selected) {
          _widgets[i].content = action._content;
          _widgets[i].url = action._url;
        }
      }
      return {
        ...state,
        widgets: _widgets,
      };

    // Drag n Droped
    case PLACE_WIDGET:
      _widgets = state.widgets;

      _widget = {
        page_number: action._element.page_number,
        content_type: action._element.name,
        uuid: action._element.uuid,
        metadata: action._element.metadata,
        content: "",
        url: "",
      };
      _widgets.push(_widget);

      return {
        ...state,
        widgets: _widgets,
      };

    // From Server
    case GET_WIDGETS:
      _widgets = [];
      action._widgets.map((_widget) => {
        _widget.uuid = uuid(); // Internal ID
        _widgets.push(_widget);
      });
      return {
        ...state,
        widgets: _widgets,
      };

    case CLEAN_WIDGETS:
      return {
        ...state,
        widgets: [],
      };

    case RM_DOM_ELEMENT:
      _widgets = state.widgets;
      for (let i = 0; i < _widgets.length; i++) {
        if (_widgets[i].uuid === action._uuid) {
          _widgets.splice(i, 1);
        }
      }
      return {
        ...state,
        widgets: _widgets,
      };
    case RM_DB_ELEMENT:
      _widgets = state.widgets;
      for (let i = 0; i < _widgets.length; i++) {
        if (_widgets[i].uuid === action._uuid) {
          _widgets.splice(i, 1);
        }
      }
      return {
        ...state,
        widgets: _widgets,
      };
    case SET_ELEMENT_PROPS:
      _contents = state.contents;
      _contents.forEach((element) => {
        if (element.uuid === action._uiid) {
          element.metadata = action._props;
        }
      });

    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

function loading() {
  return { type: EDITOR_LOADING };
}

function serverError() {
  return { type: EDITOR_SERVER_ERROR, response: { success: false } };
}

// ------------------

export function setId(_id) {
  return { type: EDITOR_ID, _id };
}

export function setBoard(_board) {
  return { type: SET_BOARD, _board };
}

export function setPanel(_panel) {
  return { type: SET_PANEL, _panel };
}

export function selectWidget(_uuid) {
  return { type: SELECT, _uuid };
}

export function setWidgetContent(_content, _url) {
  return { type: SET_WIDGET_CONTENT, _content, _url };
}

export function placeWidget(_element) {
  return { type: PLACE_WIDGET, _element };
}

export function setWidgetMetadata(_uuid, _props) {
  return { type: SET_ELEMENT_PROPS, _uuid, _props };
}

export function saveWidgets(_document_id, _contents, _token) {
  const __widgets = [];

  const _widgets = _contents;

  for (let j = 0; j < _widgets.length; j++) {
    __widgets[j] = {};
    if (_widgets[j].id) {
      __widgets[j].id = _widgets[j].id;
    }
    __widgets[j].page_number = parseInt(_widgets[j].page_number);
    __widgets[j].metadata = _widgets[j].metadata;
    __widgets[j].content = _widgets[j].content;
    __widgets[j].content_type = _widgets[j].content_type;
    __widgets[j].document_id = _document_id;
  }

  console.log("__widgets", __widgets);

  // TODO: Use Call.js to replace this functions
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "POST",
      url: `${env.REACT_APP_API_URL}/widgets/${_document_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${_token}`,
      },
      data: __widgets,
      json: true,
    })
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          return dispatch({ type: EDITOR_DONE, data: response.data });
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function cleanWidgets() {
  return { type: CLEAN_WIDGETS };
}

// NOTE: Works !!
export function getWidgets(_document_id, _token) {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "GET",
      url: `${env.REACT_APP_API_URL}/widgets/${_document_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${_token}`,
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          return dispatch({ type: GET_WIDGETS, _widgets: response.data });
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function rmDomElement(_uuid) {
  console.log("rmDomElement", _uuid);
  return { type: RM_DB_ELEMENT, _uuid };
}

export function rmDbElement(_widget_id, _token) {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "DELETE",
      url: `${env.REACT_APP_API_URL}/widgets/${_widget_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${_token}`,
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          return dispatch({ type: EDITOR_DONE });
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
