import env from "@env";
import Call from "@utils/Call";
import api from "@api";

const API_ERROR = "API_ERROR";
const LOADING = "LOADING";

const GET_MORE_DOCUMENTS = "PROFILE_GET_MORE_DOCUMENTS";

const GET_DOCUMENTS = "PROFILE_GET_DOCUMENTS";

const GET_USER = "PROFILE_GET_USER";

const GET_COLLECTIONS = "PROFILE_GET_COLLECTIONS";
const GET_MORE_COLLECTIONS = "PROFILE_GET_MORE_COLLECTIONS";

const SAVE_DOCUMENT = "PROFILE_SAVE_DOCUMENT";
const DELETE_DOCUMENT = "PROFILE_DELETE_DOCUMENT";

const initialState = {
  loading: false,
  documents: [],
  collections: [],
  user: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        loading: false,
        documents: action.data.items,
        moreDocumentsAvailable: action.data.hasMore,
      };
    case GET_COLLECTIONS:
      return {
        ...state,
        action: action.action ? action.action : null, // Always after fetching Docs, clear the action
        loading: false,
        collections: action.data.items,
      };
    case GET_USER:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case SAVE_DOCUMENT:
      let _index = state.documents.findIndex(
        (doc) => doc.id === action.data.id,
      );
      let docs = state.documents;
      docs[_index] = action.data;
      return { ...state, documents: docs };
    case DELETE_DOCUMENT:
      const _docIndex = state.documents.findIndex(
        (doc) => doc.id === action.id,
      );
      state.documents.splice(_docIndex, 1);
      return {
        ...state,
      };
    default:
      return state;
  }
};

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function getDocuments(
  _username,
  _token,
  from = 0,
  size = 100,
  keepPrevious,
) {
  return (dispatch) => {
    dispatch({ type: LOADING });
    api.Profile.getDocuments(_username, from, size, _token)
      .then(handleErrors)
      .then((response) => response.json())
      .then((documents) => {
        const hasMore = documents.length > 0 ? true : false;
        dispatch({
          type: keepPrevious === true ? GET_MORE_DOCUMENTS : GET_DOCUMENTS,
          data: { hasMore, items: documents },
        });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}

export function saveDocument(_data, _token) {
  const url = _data.document.id ? `/${_data.document.slug}` : "";
  const method = _data.document.id ? "PATCH" : "POST";

  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/documents${url}`, {
      method: method,
      body: JSON.stringify(_data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((myJson) => {
        dispatch({ type: SAVE_DOCUMENT, data: myJson });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function deleteDocument(_document, _token) {
  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/documents/${_document.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json()) // TODO: return this response to show success msg ?
      .then((data) => {
        if (data.message === "OK") {
          dispatch({ type: DELETE_DOCUMENT, id: _document.id });
        } else {
          dispatch({ type: API_ERROR });
        }
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function getCollections(
  _username,
  _token,
  from = 0,
  size = 100,
  keepPrevious,
) {
  // TODO: find out correct way to call backend for pagination (from / size)
  return (dispatch) => {
    dispatch({ type: LOADING });
    api.Profile.getCollections(_username, _token)
      .then(handleErrors)
      .then((response) => response.json())
      .then((collections) => {
        const hasMore = collections.length > 0 ? true : false;
        dispatch({
          type: keepPrevious === true ? GET_MORE_COLLECTIONS : GET_COLLECTIONS,
          data: { hasMore, items: collections },
        });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}

export function getUser(_username) {
  // TODO: find out correct way to call backend for pagination (from / size)
  return (dispatch) => {
    dispatch({ type: LOADING });
    api.Profile.getUser(_username)
      .then(handleErrors)
      .then((response) => response.json())
      .then((user) => {
        dispatch({ type: GET_USER, user });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}

export function getProfileImage(user) {
  return `${
    env.REACT_APP_API_URL
  }/users/${user}/profile_images/normal?${Math.floor(
    new Date().getTime() / 1000,
  )}`;
}

// export function getHeaderImage(user) {
//     return `${env.REACT_APP_API_URL}/users/${user}/header_images/normal?${Math.floor(new Date().getTime() / 1000)}`
// }

export async function updateProfileImage(user, img) {
  const res = await Call.put(
    `${env.REACT_APP_API_URL}/users/${user}/profile_image`,
    img,
    { "content-type": "image/jpeg" },
  );
}

export async function updateHeaderImage(user, img) {
  const res = await Call.put(
    `${env.REACT_APP_API_URL}/users/${user}/header_image`,
    img,
    { "content-type": "image/jpeg" },
  );
}

export async function getStatistics(user) {
  return await Call.get(`${env.REACT_APP_API_URL}/users/${user}/statistics`);
}

// export function getDocuments() {
//     https://www.wingpaper.com/v1/users/edgar/documents?size=12
// }
