import React from "react";

export const TrashSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 486.4 486.4">
    <g>
      <path
        d="M446,70H344.8V53.5c0-29.5-24-53.5-53.5-53.5h-96.2c-29.5,0-53.5,24-53.5,53.5V70H40.4c-7.5,0-13.5,6-13.5,13.5
              S32.9,97,40.4,97h24.4v317.2c0,39.8,32.4,72.2,72.2,72.2h212.4c39.8,0,72.2-32.4,72.2-72.2V97H446c7.5,0,13.5-6,13.5-13.5
              S453.5,70,446,70z M168.6,53.5c0-14.6,11.9-26.5,26.5-26.5h96.2c14.6,0,26.5,11.9,26.5,26.5V70H168.6V53.5z M394.6,414.2
              c0,24.9-20.3,45.2-45.2,45.2H137c-24.9,0-45.2-20.3-45.2-45.2V97h302.9v317.2H394.6z"
      />
      <path
        d="M243.2,411c7.5,0,13.5-6,13.5-13.5V158.9c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v238.5
              C229.7,404.9,235.7,411,243.2,411z"
      />
      <path
        d="M155.1,396.1c7.5,0,13.5-6,13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v208.9
              C141.6,390.1,147.7,396.1,155.1,396.1z"
      />
      <path
        d="M331.3,396.1c7.5,0,13.5-6,13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v208.9
              C317.8,390.1,323.8,396.1,331.3,396.1z"
      />
    </g>
  </svg>
);

export const LogoSvg = () => (
  <svg version="1.1" fill="currentColor" viewBox="0 0 628 117">
    <g id="Hintergrund"></g>
    <g id="Ebene_2">
      <g>
        <path
          d="M63.33,58.38c0,4.49-0.78,8.7-2.35,12.63c-1.57,3.93-3.68,7.38-6.35,10.35c-2.66,2.97-5.8,5.29-9.42,6.98
          c-3.63,1.68-7.49,2.52-11.6,2.52c-7.41,0-13.45-2.43-18.12-7.29v33.56H-0.43V58.4c0-4.41,0.78-8.58,2.35-12.52
          c1.57-3.93,3.76-7.38,6.57-10.35c2.81-2.97,6.17-5.3,10.06-6.98s8.22-2.53,12.96-2.53c4.66,0,8.94,0.84,12.84,2.53
          c3.9,1.68,7.25,4.01,10.06,6.98c2.81,2.97,5,6.42,6.57,10.35C62.54,49.8,63.33,53.97,63.33,58.38z M47.42,58.26
          c0-2.48-0.43-4.78-1.27-6.91c-0.85-2.12-2-3.96-3.45-5.52c-1.45-1.56-3.14-2.78-5.08-3.66c-1.94-0.88-3.99-1.32-6.17-1.32
          s-4.23,0.46-6.17,1.38c-1.94,0.92-3.63,2.16-5.08,3.72s-2.6,3.4-3.45,5.52c-0.85,2.12-1.27,4.38-1.27,6.79
          c0,2.48,0.42,4.81,1.27,6.97c0.85,2.16,2,4.04,3.45,5.65c1.45,1.6,3.14,2.86,5.08,3.78c1.94,0.92,3.99,1.38,6.17,1.38
          s4.23-0.46,6.17-1.38c1.94-0.92,3.63-2.16,5.08-3.72c1.45-1.56,2.6-3.42,3.45-5.58C46.99,63.19,47.42,60.82,47.42,58.26z"
        />
        <path
          d="M103.77,25.9c4.34,0,8.39,0.82,12.17,2.47c3.78,1.65,7.09,3.94,9.94,6.87c2.85,2.93,5.1,6.39,6.75,10.36
          s2.47,8.3,2.47,12.96c0,4.66-0.82,8.96-2.47,12.9c-1.65,3.94-3.9,7.35-6.75,10.24c-2.85,2.89-6.17,5.16-9.94,6.81
          c-3.78,1.65-7.84,2.47-12.17,2.47c-4.34,0-8.4-0.83-12.17-2.47c-3.78-1.65-7.07-3.92-9.88-6.81c-2.82-2.89-5.05-6.31-6.69-10.24
          c-1.65-3.94-2.47-8.24-2.47-12.9c0-4.66,0.82-8.98,2.47-12.96c1.65-3.98,3.87-7.43,6.69-10.36c2.81-2.93,6.1-5.22,9.88-6.87
          C95.37,26.72,99.43,25.9,103.77,25.9z M103.83,76.4c2.1,0,4.07-0.42,5.93-1.27c1.85-0.84,3.49-2.05,4.9-3.62s2.52-3.43,3.33-5.6
          c0.81-2.17,1.21-4.58,1.21-7.23c0-2.65-0.41-5.1-1.21-7.35c-0.81-2.25-1.91-4.18-3.33-5.79c-1.41-1.6-3.04-2.85-4.9-3.73
          c-1.86-0.88-3.83-1.33-5.93-1.33c-2.1,0-4.1,0.44-5.99,1.33c-1.9,0.88-3.53,2.13-4.9,3.73c-1.37,1.61-2.46,3.54-3.27,5.79
          c-0.81,2.25-1.21,4.7-1.21,7.35c0,2.65,0.4,5.06,1.21,7.23c0.81,2.17,1.89,4.04,3.27,5.6c1.37,1.57,3,2.77,4.9,3.62
          C99.73,75.98,101.73,76.4,103.83,76.4z"
        />
        <path
          d="M240.56,63.26c0,3.94-0.66,7.61-1.99,11.03c-1.33,3.42-3.22,6.37-5.67,8.86c-2.45,2.49-5.4,4.44-8.86,5.84
          c-3.46,1.41-7.27,2.11-11.45,2.11c-4.26,0-8.06-0.78-11.39-2.35c-3.33-1.57-6.17-3.72-8.5-6.45c-2.33,2.73-5.18,4.88-8.56,6.45
          c-3.37,1.57-7.11,2.35-11.21,2.35c-4.34,0-8.2-0.68-11.57-2.05s-6.25-3.27-8.62-5.73c-2.37-2.45-4.18-5.38-5.43-8.8
          c-1.24-3.41-1.87-7.17-1.87-11.27v-35.8h15.91v36.28c0,0.88,0.12,1.99,0.36,3.31c0.24,1.33,0.76,2.63,1.57,3.92
          c0.8,1.29,1.97,2.37,3.49,3.25c1.53,0.88,3.58,1.33,6.15,1.33c3.62,0,6.53-1.08,8.74-3.25c2.21-2.17,3.31-5.02,3.31-8.56V27.47
          h15.91v36.28c0,0.88,0.12,1.99,0.36,3.31c0.24,1.33,0.76,2.63,1.57,3.92c0.8,1.29,1.97,2.37,3.49,3.25
          c1.53,0.88,3.61,1.33,6.27,1.33c3.54,0,6.43-1.08,8.68-3.25c2.25-2.17,3.38-5.02,3.38-8.56V27.47h15.91V63.26z"
        />
        <path
          d="M312.75,69.77c-1.85,6.35-5.36,11.47-10.54,15.37c-5.18,3.9-11.67,5.85-19.47,5.85c-4.42,0-8.56-0.84-12.41-2.53
          c-3.86-1.69-7.23-4-10.13-6.93c-2.89-2.93-5.16-6.37-6.81-10.31c-1.65-3.94-2.47-8.15-2.47-12.65s0.82-8.74,2.47-12.72
          c1.65-3.98,3.92-7.43,6.81-10.36c2.89-2.93,6.27-5.26,10.13-6.99c3.86-1.73,7.99-2.59,12.41-2.59c3.69,0,7.23,0.58,10.61,1.75
          c3.37,1.17,6.41,2.85,9.1,5.06c2.69,2.21,4.98,4.92,6.87,8.14c1.89,3.21,3.19,6.91,3.92,11.09l-43.87,18.44
          c1.6,2.09,3.53,3.72,5.79,4.88c2.25,1.17,4.74,1.75,7.47,1.75c2.57,0,5.06-0.58,7.47-1.75c2.41-1.16,4.3-2.99,5.66-5.48H312.75z
          M294.91,45.42c-1.45-1.77-3.21-3.13-5.3-4.1c-2.09-0.96-4.42-1.45-6.99-1.45c-2.33,0-4.5,0.44-6.5,1.32
          c-2.01,0.88-3.76,2.11-5.24,3.68c-1.49,1.57-2.69,3.44-3.61,5.6c-0.93,2.17-1.43,4.58-1.51,7.23L294.91,45.42z"
        />
        <path
          d="M356.62,41.21c-5.06-0.88-9.14-0.14-12.23,2.23c-3.09,2.37-4.64,5.77-4.64,10.19v35.92h-15.91V53.62
          c0-4.42,0.74-8.44,2.23-12.05c1.49-3.61,3.63-6.67,6.45-9.16c2.81-2.49,6.25-4.3,10.31-5.42c4.06-1.12,8.66-1.36,13.8-0.72V41.21z
          "
        />
        <path
          d="M426.08,58.32c0,18.44-13.5,32.66-31.09,32.66c-17.24,0-31.22-13.98-31.22-32.66c0-18.56,13.86-32.42,30.98-32.42
          c8.68,0,16.39,4.1,21.45,10.49V-0.14h9.88V58.32z M394.98,35.3c-11.57,0-20.97,10.12-20.97,23.02c0,13.14,9.4,23.26,20.97,23.26
          c11.33,0,20.97-10.36,20.97-23.26C415.95,45.54,406.43,35.3,394.98,35.3z"
        />
        <path
          d="M498.63,58.68c0,17.84-13.5,32.42-31.22,32.42c-17.6,0-31.22-14.58-31.22-32.42c0-18.08,13.62-32.78,31.22-32.78
          C485.13,25.9,498.63,40.6,498.63,58.68z M488.39,58.68c0-12.65-9.4-23.02-20.97-23.02c-11.45,0-20.85,10.37-20.85,23.02
          c0,12.41,9.4,22.54,20.85,22.54C478.98,81.22,488.39,71.1,488.39,58.68z"
        />
        <path
          d="M538.28,90.98c-17.11,0-31.09-14.46-31.09-32.42c0-17.96,13.98-32.66,31.09-32.66c13.86,0,24.71,8.2,28.93,20.73h-10.97
          c-3.5-6.75-9.88-10.97-17.96-10.97c-11.45,0-20.85,10.37-20.85,22.9s9.4,22.66,20.85,22.66c8.2,0,14.58-4.22,17.96-10.97h10.97
          C563.11,82.79,552.14,90.98,538.28,90.98z"
        />
        <path
          d="M628.43,79.41v10.12H618.3V78.69c0-8.92-5.3-15.43-14.95-15.43c-9.64,0-14.95,6.51-14.95,15.43v10.85h-10.12V79.41
          c0-9.64,3.98-16.87,10.61-20.97c-6.63-4.1-10.61-11.21-10.61-20.97v-10h10.12v10.85c0,8.92,5.3,15.43,14.95,15.43
          c9.64,0,14.95-6.51,14.95-15.43V27.46h10.12v10c0,9.76-3.98,16.87-10.61,20.97C624.45,62.54,628.43,69.77,628.43,79.41z"
        />
      </g>
    </g>
  </svg>
);
