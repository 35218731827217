import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { hashHistory } from 'react-router'
import { forgotPassword } from "./Auth.duck";
import Button from "@common/Button/Button.js";
import { Form, Input, Icon, Alert } from "antd";
import { push } from "connected-react-router";
import { message } from "antd";
import styles from "./Forgot.module.scss";

const FormItem = Form.Item;

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: null,
      submited: false,
      msg: null,
    };
  }

  componentDidMount() { }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ msg: null }, () => {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          const res = await forgotPassword(values.email);
          if (res.errors) {
            this.setState({ msg: res.errors.email });
          } else {
            message.success(
              "You will receive an email with instructions on how to reset your password",
            );
            setTimeout(() => {
              this.props.goHome()
            }, 2000)
          }
          // forgotPassword(values.email).then(forgot => {
          //   this.setState({
          //     success: forgot.response.success,
          //     msg: forgot.response.msg
          //   });
          // });
        }
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.wrapper}>
        <div id="forgot">
          <h1>Reset your password</h1>
          {this.state.msg ? (
            <Alert
              onClose={this.onAlertClose}
              showIcon
              message={this.state.msg}
              type={this.state.success ? "success" : "error"}
            />
          ) : null}
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ],
              })(
                <Input
                  placeholder="email"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                />,
              )}
            </FormItem>
            <FormItem>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit">
                Send instructions
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // forgot
      goHome: () => push('/')
    },
    dispatch,
  );

const ForgotForm = Form.create({})(Forgot);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotForm);
