import React from "react";
import { Link, withRouter } from "react-router-dom";
import styles from "./Footer.module.scss";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewer: false
    };
  }

  isViewer = (pathname) => pathname.match(/^\/viewer\/.*/);
  isEditor = (pathname) => pathname.match(/^\/editor\/.*/);

  componentDidMount() {

    const _path = this.props.history.location.pathname;

    if (this.isViewer(_path) || this.isEditor(_path)) {
      this.setState({ isViewer: true });
    }
    this.props.history.listen((location) => {
      if (this.isViewer(location.pathname) || this.isEditor(location.pathname)) {
        this.setState({ isViewer: true });
      } else {
        this.setState({ isViewer: false });
      }
    });
  }

  render() {
    if (!this.state.isViewer) {
      return (
        <footer className={styles.footer}>
          <Link to="/about">About us</Link>
          <Link to="/pricing">Pricing</Link>
          {/* 
          <Link to="/team">Team</Link>
          <Link to="/career">Career</Link>
          */}
          <Link to="/imprint">Imprint</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </footer>
      );
    } else {
      return null
    }
  }
}
export default withRouter(Footer);
