import React from "react";
import styles from "./MobileMenu.module.scss";
import CloseSvg from "@styles/icons/close-svg.js";

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onClick, hasCloseButton } = this.props;
    return (
      <div className={styles.menu}>
        {this.props.items.map((i, index) => {
          return (
            <div
              key={index}
              className={styles.item}
              onClick={() => {
                onClick();
                i.onClick();
              }}>
              {i.name}
            </div>
          );
        })}
        {hasCloseButton ? (
          <div className={styles.close} onClick={onClick}>
            <CloseSvg />
          </div>
        ) : null}
      </div>
    );
  }
}
export default MobileMenu;
