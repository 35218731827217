import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "@auth/Auth.duck";
// import user from '@comp/User/User.duck';
import dashboard from "@dash/Dash.duck";
import editor from "@editor/Editor.duck";
import viewer from "@viewer/Viewer.duck";
import search from "@search/Search.duck";
import general from "@comp/General/General.duck.js";
import profile from "@comp/Profile/Profile.duck.js";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: auth,
    general: general,
    // user: user,
    viewer: viewer,
    editor: editor,
    search: search,
    dashboard: dashboard,
    profile: profile,
  });
export default createRootReducer;
