import React from "react";
import styles from "./Button.module.scss";
import { Button as AButton } from "antd";
import classNames from "classnames";

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    switch (this.props.type) {
      case "large":
        this.style = classNames(styles.large, styles.border);
        break; //White BG, Black Color, Black Border, Large Format
      case "largeInverted":
        this.style = classNames(styles.large, styles.largeInverted);
        break; //White BG, Black Color, Black Border, Large Format
      case "basic":
        this.style = classNames(styles.basic, styles.border);
        break; //White BG, Black Color, Black Border
      case "primary":
        this.style = styles.primary;
        break; //Red BG, White Color, No Border
      case "secondary":
        this.style = styles.secondary;
        break; //White BG, Red Color, No Border
      case "secondary-border":
        this.style = classNames(styles.secondary, styles.border);
        break; //White BG, Red Color
      case "success":
        this.style = styles.success;
        break;
      // default: this.style = styles.basic;
    }
  }

  render() {
    const { customIcon, ...props } = this.props; // separate customIcon prop and pass only the rest to antd button
    let classes = [styles.button, this.style];
    if (customIcon) {
      classes.push(styles.customIcon);
    }
    return (
      <AButton
        onClick={this.props.onClick}
        className={classNames(classes)}
        style={this.props.style}
        {...props}>
        {customIcon ? customIcon : null}
        {this.props.children}
      </AButton>
    );
  }
}
export default Button;
