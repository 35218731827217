import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./UpgradeTeaser.module.scss";
import Modal from "@common/Modal/Modal";
import Button from "@common/Button/Button.js";
import { setUpgradeTeaser } from "@comp/General/General.duck.js";
import { push } from "connected-react-router";

class UpgradeTeaser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal>
        <h1>Upgrade now</h1>
        <div>
          You just tried to use a premium feature. Upgrade now to use it ;)
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              this.props.setUpgradeTeaser(false);
              this.props.goPlans();
            }}>
            show plans
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUpgradeTeaser,
      goPlans: () => push("/settings/subscription/pricing"),
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(UpgradeTeaser);
