import env from "@env";

class Profile {
  static getDocuments(_username, from, size, _token) {
    return fetch(
      `${env.REACT_APP_API_URL}/users/${_username}/documents?from=${from}&size=${size}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${_token}`,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }

  static getCollections(_username, _token) {
    return fetch(`${env.REACT_APP_API_URL}/users/${_username}/collections`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  static getUser(_username) {
    return fetch(`${env.REACT_APP_API_URL}/users/${_username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
}

export default Profile;
