import axios from "axios";
import store from "@store/store";
import env from "@env";
import { head } from "lodash";

class Call {
  static async get(url, params) {
    return await this.call("GET", url, undefined, params);
  }

  static async post(url, data) {
    return await this.call("POST", url, data);
  }

  static async put(url, data, headers) {
    return await this.call("PUT", url, data, undefined, headers);
  }

  static async patch(url, data) {
    return await this.call("PATCH", url, data);
  }

  static async delete(url, data) {
    return await this.call("DELETE", url, data);
  }

  static async call(method, url, data, params, headers) {
    let res;

    if (headers === undefined) {
      headers = {};
    }

    if (headers["Content-Type"] === undefined) {
      headers["Content-Type"] = "application/json";
    }
    if (headers["authorization"] === undefined) {
      headers["authorization"] = `Bearer ${store.getState().auth.jwt}`;
    }
    if (headers["accept"] === undefined) {
      headers["accept"] = "application/json";
    }

    try {
      res = await axios({
        method,
        // url: env.REACT_APP_API_URL + url,
        url,
        headers,
        data,
        params,
        json: true,
      });
    } catch (err) {
      res = err.response;
    }
    return res.data;
  }
}

export default Call;
