import React from "react";
import styles from "./ProfileImage.module.scss";
import { connect } from "react-redux";
import classNames from "classnames";

class ProfileImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { editMode, onClick, isInHeader, src } = this.props;
    let classes = [styles.imgWrapper];
    if (editMode) classes.push(styles.edit);

    return (
      <div className={styles.profileImage}>
        <div
          className={
            isInHeader
              ? `${classNames(classes)} ${styles.inHeader}`
              : `${classNames(classes)} ${styles.inNavbar}`
          }>
          <div
            style={{ backgroundImage: `url(${src})` }}
            className={
              isInHeader
                ? `${styles.img} ${styles.inHeader} `
                : `${styles.img} ${styles.inNavbar}`
            }>
            {editMode ? (
              <div onClick={onClick} className={styles.overlay}>
                Change your profile picture
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(undefined, undefined)(ProfileImage);
