const UPGRADE_TEASER_SET = "UPGRADE_TEASER_SET";

const initialState = {
  showUgradeTeaser: false,
};

// REDUCERS --- : Handle the result of the actions

export default (state = initialState, action) => {
  switch (action.type) {
    case UPGRADE_TEASER_SET:
      return {
        ...state,
        showUgradeTeaser: action.data,
        server_error: false,
      };
    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

export function setUpgradeTeaser(showTeaser) {
  return { type: UPGRADE_TEASER_SET, data: showTeaser };
}
