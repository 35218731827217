import React from "react";
import styles from "./CenteredContent.module.scss";
import classNames from "classnames";

class CenteredContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let classes = [styles.container];
    if (this.props.className) {
      classes.push(this.props.className);
    }
    return <div className={classNames(classes)}>{this.props.children}</div>;
  }
}
export default CenteredContent;
