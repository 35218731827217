import React, { lazy } from "react";
import { Route, Switch } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "@styles/App.scss";
import { setUser } from "@auth/Auth.duck";
import UpgradeTeaser from "@common/UpgradeTeaser/UpgradeTeaser";
// import Payment from "@payment/Payment";
import Forgot from "@auth/Forgot.js";
import Password from "@comp/Password/Password";
import Topbar from "@common/topbar/Topbar";
import styles from "./App.module.scss";
import Footer from "@common/Footer/Footer";
import env from "@env";

// Cookie Concent
import CookieConsent, { Cookies } from "react-cookie-consent";

// Containers
const Home = lazy(() => import("@home/Home.container"));
const Auth = lazy(() => import("@auth/Auth.container"));
const Dash = lazy(() => import("@dash/Dash.container"));
const Misc = lazy(() => import("@misc/Misc.container"));
const Viewer = lazy(() => import("@viewer/Viewer.container"));
const Embed = lazy(() => import("@viewer/Embed.container"));
const Editor = lazy(() => import("@editor/Editor.container"));
const Search = lazy(() => import("@search/Search.container"));
const Profile = lazy(() => import("@comp/Profile/Profile.container"));
const Settings = lazy(() => import("@settings/Settings.container"));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "/",
    };
  }

  componentDidMount() {
    console.log(process.env);
    this.setOverflow();
    this.setAuth();
  }

  setAuth() {
    const token = window.localStorage.getItem("apiToken");
    let user = window.localStorage.getItem("reduxPersist:loggedUser");
    if (user) {
      user = JSON.parse(user);
      this.props.setUser(user, token);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const _url = props.router.location.pathname;
    return { url: _url };
  }

  componentDidUpdate() {
    this.setOverflow();
  }

  setOverflow() {
    // TODO: use below to allow setting size in % (easier to do centered positioning and fixed topbar ?)
    // document.body.style.overflow = "hidden";
    // document.getElementById('root').style.height = "100%";
    if (this.state.url.includes("editor")) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  render() {
    return (
      <React.Fragment>
        <Topbar />
        {this.props.general.showUgradeTeaser ? <UpgradeTeaser /> : null}
        <div className={styles.content}>
          <Switch>
            <Route exact path="/" component={Home} />

            {/* AUTH */}
            <Route exact path="/auth" component={Auth} />
            <Route exact path="/login" component={Auth} />
            <Route exact path="/logout" component={Auth} />
            <Route exact path="/signup" component={Auth} />
            <Route exact path="/forgot" component={Auth} />
            <Route exact path="/confirm" component={Auth} />
            <Route exact path="/password" component={Password} />

            {/* PROFILE */}
            <Route exact path="/users/:username" component={Profile} />
            <Route
              exact
              path="/users/:username/collections"
              component={Profile}
            />
            <Route
              exact
              path="/users/:username/collections/:slug"
              component={Profile}
            />

            {/* DASHBOARD */}
            <Route exact path="/documents/add" component={Dash} />
            <Route exact path="/documents/settings/:id" component={Dash} />
            <Route exact path="/collections/add" component={Dash} />
            <Route exact path="/collections/edit/:id" component={Dash} />

            {/* VIEWER */}
            <Route exact path="/viewer/:id" component={Viewer} />
            <Route exact path="/viewer/:id/:page" component={Viewer} />
            <Route exact path="/embed/:id" component={Embed} />
            <Route exact path="/embed/:id/:page" component={Embed} />

            {/* EDITOR */}
            <Route exact path="/editor/:id" component={Editor} />
            <Route exact path="/editor/:id/:page" component={Editor} />

            {/* SETTINGS */}
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/settings/viewer" component={Settings} />
            <Route exact path="/settings/profile" component={Settings} />
            <Route exact path="/settings/login" component={Settings} />
            <Route path="/settings/subscription" component={Settings} />

            {/* SEARCH */}
            <Route exact path="/search" component={Search} />
            <Route exact path="/search/:term" component={Search} />
            <Route exact path="/search/:term/:page" component={Search} />

            {/* MISC */}
            <Route exact path="/about" component={Misc} />
            <Route exact path="/pricing" component={Misc} />
            <Route exact path="/team" component={Misc} />
            <Route exact path="/career" component={Misc} />
            <Route exact path="/imprint" component={Misc} />
            <Route exact path="/terms" component={Misc} />

            <Route
              render={(props) => <div>Miss: {JSON.stringify(props)}</div>}
            />
          </Switch>
          <Footer />
          <CookieConsent
            sameSite="strict"
            location="bottom"
            buttonText="Ok"
            cookieName="powerdocx"
            style={{ background: "#2B373B" }}
            buttonStyle={{
              color: "#fff",
              fontSize: "13px",
              background: "#D33F49",
            }}
            expires={150}>
            This website uses cookies to enhance the user experience.{" "}
          </CookieConsent>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
  general: state.general,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default App;
