import React from "react";
import { withRouter } from "react-router-dom";
import MobileMenu from "@common/MobileMenu/MobileMenu";

class MobileMenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loggedIn, username, onClick } = this.props;

    let mobileMenuItems = [];
    if (loggedIn) {
      mobileMenuItems = [
        {
          name: "Profile",
          onClick: () => this.props.history.push(`/users/${username}`),
        },
        {
          name: "Pricing",
          onClick: () =>
            this.props.history.push(`/settings/subscription/pricing`),
        },
        // {
        //   name: "About Us",
        //   onClick: () =>
        // this.props.history.push(``),
        // },
        // {
        //   name: "Career",
        //   onClick: () =>
        // this.props.history.push(``),
        // },
        {
          name: "Settings",
          onClick: () => this.props.history.push(`/settings`),
        },
        // {
        //   name: "Imprint",
        //   onClick: () =>
        // this.props.history.push(``),
        // },
        // {
        //   name: "Terms & Conditions",
        //   onClick: () =>
        //     this.props.history.push(`/settings/subscription/pricing`),
        // },
        { name: "Logout", onClick: () => this.props.history.push("/logout") },
      ];
    } else {
      mobileMenuItems = [
        {
          name: "Login",
          onClick: () => {
            this.props.history.push("/login");
          },
        },
        {
          name: "Sign Up",
          onClick: () => this.props.history.push("/signup"),
        },
      ];
    }

    return <MobileMenu onClick={onClick} items={mobileMenuItems} />;
  }
}
export default withRouter(MobileMenuContainer);
