import React from "react";
import styles from "./Password.module.scss";
import { TextField, FormControl, InputLabel } from "@material-ui/core";
import Button from "@common/Button/Button";
import { changePassword } from "@auth/Auth.duck";
import Topbar from "@common/topbar/Topbar";
import CenteredContent from "@common/CenteredContent/CenteredContent";
import { Alert, message } from "antd";

/*
    TODO: 
    - form validation
        - new pw >= 8 characters
        - new pw === cofirmation
    - show error msgs in a better way (e.g. below respective form field)

*/
class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordOld: "",
      password: "",
      passwordConfirmation: "",
    };
  }

  onTextChange = (e, prop) => {
    const val = e.target.value;
    this.setState((s) => {
      s[prop] = val;
      return s;
    });
  };

  async save() {
    this.setState({ msg: null }, async () => {
      const { passwordOld, password, passwordConfirmation } = this.state;
      const res = await changePassword(
        passwordOld,
        password,
        passwordConfirmation,
      );
      if (res.errors) {
        this.setState({ msg: res.errors });
      } else {
        message.success("Your password has been successfully updated!");
      }
    });
  }

  renderError(msg) {
    return (
      <Alert
        closable
        onClose={this.onAlertClose}
        showIcon
        message={msg}
        type="error"
        description={
          <React.Fragment>
            {/*<Link to="/forgot">Trouble logging in ?</Link>*/}
          </React.Fragment>
        }
      />
    );
  }
  render() {
    const inputSettings = {
      // variant: "outlined",
      size: "small",
    };

    return (
      <div>
        <CenteredContent className={styles.centered}>
          <h1>Change Password</h1>
          {this.state.msg
            ? Object.keys(this.state.msg).map((err) => {
                return this.state.msg[err].map((e) => {
                  return this.renderError(err + " " + e);
                });
              })
            : null}
          <div>
            <form className={styles.container}>
              <TextField
                label="Current Password"
                type="password"
                onChange={(e) => {
                  this.onTextChange(e, "passwordOld");
                }}
                className={styles.input}
                {...inputSettings}
              />
              <TextField
                label="New Password"
                type="password"
                onChange={(e) => {
                  this.onTextChange(e, "password");
                }}
                className={styles.input}
                {...inputSettings}
              />
              <TextField
                label="Verify Password"
                type="password"
                onChange={(e) => {
                  this.onTextChange(e, "passwordConfirmation");
                }}
                className={styles.input}
                {...inputSettings}
              />
              <Button
                type="primary"
                onClick={() => {
                  this.save();
                }}>
                save
              </Button>
              {/* <div>Forgot password?</div> */}
            </form>
          </div>
        </CenteredContent>
      </div>
    );
  }
}
export default Password;
