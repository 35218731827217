import React from "react";

export default (props) => {
  let style = {
    // set defaults
    width: "1.5em",
    height: "1.5em",
    ...props.style, // allow overriding defaults
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      viewBox="0 0 12.942 19.731">
      {/* <defs><style>.a{fill:#fff;}.b{fill:none;stroke:#fff;}</style></defs> */}
      <g transform="translate(-21 -19.269)">
        <path
          d="M83.882,203.347v-5.211a.374.374,0,0,1,.35-.394h2.2a.4.4,0,0,0,.256-.662l-6.12-7.385a.324.324,0,0,0-.512,0l-6.12,7.385a.4.4,0,0,0,.256.662h2.2a.374.374,0,0,1,.35.394v5.211"
          transform="translate(-52.842 -170.302)"
        />
        <line x2="7.15" transform="translate(23.9 38.5)" />
        <line x2="7.15" transform="translate(23.9 35.5)" />
      </g>
    </svg>
  );
};
