import env from "@env";

const SEARCH = "SEARCH";
const SEARCH_EXTERNAL = "SEARCH_EXTERNAL";

const API_ERROR = "API_ERROR";

const initialState = {
  hits: [],
  suggestions: [],
  total: 0,
};

// REDUCERS --- : Handle the result of the actions

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        ...action.data,
      };

    case SEARCH_EXTERNAL:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

export function documentSearch(_term, _from = 0, _size = 15) {
  return (dispatch) =>
    fetch(
      `${env.REACT_APP_API_URL}/search?search_text=${_term}&from=${_from}&size=${_size}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((_json) => {
        dispatch({ type: SEARCH, data: _json });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function externalSearch(_term, _from = 0, _size = 15) {
  return (dispatch) =>
    fetch(
      `${env.REACT_APP_API_URL}/search/external_documents?search_text=${_term}&from=${_from}&size=${_size}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((_json) => {
        dispatch({ type: SEARCH_EXTERNAL, data: _json });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}
