import env from "@env";

class Subscription {
  static getFeatures(_token) {
    return fetch(`${env.REACT_APP_API_URL}/users/active_features`, {
      headers: { Authorization: `bearer ${_token}` },
    });
  }
}

export default Subscription;
