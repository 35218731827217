import axios from "axios";
import moment from "moment";
import Call from "@utils/Call.js";
import env from "@env";
import _ from "lodash";

const VIEWER_LOADING = "VIEWER_LOADING";
const VIEWER_SERVER_ERROR = "VIEWER_SERVER_ERROR";
const VIEWER_DONE = "VIEWER_DONE";

const VIEWER_SET = "VIEWER_SET";

const DOCUMENT_SET = "DOCUMENT_SET";
const WIDGETS_SET = "WIDGETS_SET";

const DOCUMENTS_SET = "DOCUMENTS_SET";
const PAGE_SET = "PAGE_SET";

const SEARCH_SET = "SEARCH_SET";
const SEARCH_INDEX_SET = "SEARCH_INDEX_SET";

const initialState = {
  server_error: false,
  loading: false,
  viewer: null,
  document: null,
  documents: [],
  widgets: null,
  pages: {},
  search: {},
  searchIndex: 0,
  currentPage: null, // Current Page
  id: null, // Document Id
};

// REDUCERS --- : Handle the result of the actions

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEWER_LOADING:
      return {
        ...state,
        server_error: false,
        loading: true,
      };
    case VIEWER_DONE:
      return {
        ...state,
        server_error: false,
        loading: false,
      };
    case VIEWER_SET:
      return {
        ...state,
        viewer: action._viewer,
        server_error: false,
        loading: false,
      };
    case PAGE_SET:
      const __page = state.pages;
      __page[action._page] = action._data;
      // console.log("__page", __page);
      // console.log("action._page", action._page);
      // console.log("action._data", action._data);
      return {
        ...state,
        pages: __page,
        currentPage: action._page,
        server_error: false,
        loading: false,
      };

    case DOCUMENT_SET:
      return {
        ...state,
        document: action._document,
        id: action._document_id,
        server_error: false,
        loading: false,
      };
    case DOCUMENTS_SET:
      return {
        ...state,
        documents: action._documents,
      };
    case WIDGETS_SET:
      return {
        ...state,
        widgets: action._widgets,
        server_error: false,
        loading: false,
      };
    case SEARCH_SET:
      return {
        ...state,
        search: action._data ? action._data.hits : {},
      };
    case SEARCH_INDEX_SET:
      return {
        ...state,
        searchIndex: action._index,
      };
    case VIEWER_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        server_error: true,
      };
    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

function loading() {
  return { type: VIEWER_LOADING };
}

function serverError() {
  return { type: VIEWER_SERVER_ERROR, response: { success: false } };
}

function setPage(_data, _page) {
  return { type: PAGE_SET, _data, _page };
}

function setSearch(_data) {
  return { type: SEARCH_SET, _data };
}
export function clearSearch() {
  return { type: SEARCH_SET };
}

export function setSearchIndex(_index) {
  return { type: SEARCH_INDEX_SET, _index };
}

export function setDocument(_document) {
  return { type: DOCUMENT_SET, _document, _document_id: _document.id };
}

function setDocuments(_data) {
  return { type: DOCUMENTS_SET, _documents: _data };
}

export function getDocuments() {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "GET",
      url: `${env.REACT_APP_API_URL}/documents`,
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log("response.data", response.data);
          return dispatch(setDocuments(response.data));
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function getDocument(_document_id) {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "GET",
      url: `${env.REACT_APP_API_URL}/documents/${_document_id}`,
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          return dispatch({
            type: DOCUMENT_SET,
            _document: response.data,
            _document_id: response.data.id,
          });
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function getWidgets(_document_id) {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "GET",
      url: `${env.REACT_APP_API_URL}/documents/${_document_id}/widgets`,
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Widgets", response);
          return dispatch({ type: WIDGETS_SET, _widgets: response.data });
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

// url: `${env.REACT_APP_API_URL}/documents/${_document_id}/page/${_page}`,
// `${env.REACT_APP_API_URL}/documents/${_document_id}/content/${_page}`
// url: `${env.REACT_APP_API_URL}/documents/${_document_id}/v2_page/${_page}`,

export function getPage(_document_id, _page) {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "GET",
      url: `${env.REACT_APP_API_URL}/documents/${_document_id}/v2_page/${_page}`,
      headers: {
        "Content-Type": "text/plain",
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          return dispatch(setPage(response.data, _page));
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function getPageWidgets(_document_id, _page) {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "GET",
      url: `${env.REACT_APP_API_URL}/documents/${_document_id}/widgets_page/${_page}`,
      headers: {
        "Content-Type": "text/plain",
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          return dispatch(setPage(response.data, _page));
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function getSearch(_document_id, _query) {
  return (dispatch) => {
    dispatch(loading());
    return axios({
      method: "GET",
      url: `${env.REACT_APP_API_URL}/elastic/search/${_document_id}?query=${_query}`,
      headers: {
        "Content-Type": "text/plain",
      },
      json: true,
    })
      .then((response) => {
        if (response.status === 200) {
          return dispatch(setSearch(response.data));
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
