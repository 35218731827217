import React from "react";

export default (props) => {
  let style = {
    // set defaults
    width: "1.5em",
    height: "1.5em",
    fill: "none",
    stroke: "#fff",
    // width="15.063" height="15.518"
    ...props.style, // allow overriding defaults
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      viewBox="0 0 15.063 15.518">
      {/* <defs><style>.a,.c{fill:none;}.a{stroke:#fff;stroke-miterlimit:10;}.b{stroke:none;}</style></defs> */}
      <g transform="translate(-1328.5 -411)">
        <g transform="translate(1329 411)">
          <path
            className="a"
            d="M317,50.759c0-4.285,3.148-7.759,7.031-7.759s7.031,3.474,7.031,7.759"
            transform="translate(-317 -35.241)"
          />
          <g className="a" transform="translate(3)">
            <circle className="b" cx="4" cy="4" r="4" />
            <circle className="c" cx="4" cy="4" r="3.5" />
          </g>
        </g>
      </g>
    </svg>
  );
};
