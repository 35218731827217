import env from "@env";
import Call from "@utils/Call.js";
import api from "@api";

const API_ERROR = "API_ERROR";
const LOADING = "LOADING";
const DONE = "DONE";

// Documents
const SAVED_DOCUMENT = "SAVED_DOCUMENT";
const GET_DOCUMENTS = "GET_DOCUMENTS";
const GET_MORE_DOCUMENTS = "GET_MORE_DOCUMENTS";
const GET_DOCUMENT = "GET_DOCUMENT";
const SELECT_DOCUMENT = "SELECT_DOCUMENT";
const EDIT_DOCUMENT = "EDIT_DOCUMENT";
const DELETE_DOCUMENT = "DELETE_DOCUMENT";
const SAVE_DOCUMENT = "SAVE_DOCUMENT";

// Collections
const GET_COLLECTIONS = "GET_COLLECTIONS";
const GET_MORE_COLLECTIONS = "GET_MORE_COLLECTIONS";
const SAVED_COLLECTION = "SAVED_COLLECTION";
const EDIT_COLLECTION = "EDIT_COLLECTION";
const SELECT_COLLECTION = "SELECT_COLLECTION";

// Custom Viewer
const CUSTOM_VIEWER = "CUSTOM_VIEWER";
const SET_CUSTOM_VIEWER_PROP = "SET_CUSTOM_VIEWER_PROP";

const initialState = {
  loading: false,
  documents: [],
  documents_selection: [],
  document_edit: null,
  collections: [],
  collections_selection: [],
  collection_edit: null,
  custom_viewer: {
    topbar_color: "#D33F49",
    logo: null,
    bg_color: "#fff",
    arrow_color: "#000",
  },
};

// REDUCERS --- : Handle the result of the actions

let _selection;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        loading: false,
        documents: action.data.items,
        moreDocumentsAvailable: action.data.hasMore,
        selection: false,
        panel: "documents",
        subpanel: null,
      };

    case GET_MORE_DOCUMENTS: // add new items to already fetched ones
      return {
        ...state,
        loading: false,
        documents: state.documents.concat(action.data.items),
        moreDocumentsAvailable: action.data.hasMore,
      };

    case GET_COLLECTIONS:
      return {
        ...state,
        action: action.action ? action.action : null, // Always after fetching Docs, clear the action
        loading: false,
        collections: action.data.items,
      };

    case GET_MORE_COLLECTIONS: // add new items to already fetched ones
      return {
        ...state,
        action: action.action ? action.action : null, // Always after fetching Docs, clear the action
        loading: false,
        collections: state.collections.concat(action.data.items),
      };

    case SAVE_DOCUMENT:
      let _index = state.documents.findIndex(
        (doc) => doc.id === action.data.id,
      );
      return { ...state };

    case SAVED_DOCUMENT:
      return {
        ...state,
        loading: false,
        action: "documentSaved",
      };

    case SELECT_DOCUMENT:
      _selection = state.documents_selection;
      // Check if document is on the array
      const _i = _selection.indexOf(action.id);
      if (_i >= 0) {
        _selection.splice(_i, 1);
      } else {
        _selection.push(action.id);
      }
      return {
        ...state,
        documents_selection: _selection,
      };

    case GET_DOCUMENT:
      return {
        ...state,
        document_edit: action.data,
      };

    case EDIT_DOCUMENT:
      const _doc = state.documents.find((doc) => doc.id === action.id);
      return {
        ...state,
        document_edit: _doc,
      };

    case DELETE_DOCUMENT:
      const _docIndex = state.documents.findIndex(
        (doc) => doc.id === action.id,
      );
      state.documents.splice(_docIndex, 1);
      return {
        ...state,
      };

    case EDIT_COLLECTION:
      // console.log("action.data", action.data);
      return {
        ...state,
        collection_edit: action.data,
      };

    case SAVED_COLLECTION:
      return {
        ...state,
        loading: false,
      };

    case SELECT_COLLECTION:
      _selection = state.collections_selection;
      // Check if document is on the array
      const _j = _selection.indexOf(action.id);
      if (_j >= 0) {
        _selection.splice(_j, 1);
      } else {
        _selection.push(action.id);
      }
      return {
        ...state,
        collections_selection: _selection,
      };

    case CUSTOM_VIEWER:
      return {
        ...state,
        custom_viewer: action._viewer ? action._viewer : state.custom_viewer,
      };

    case SET_CUSTOM_VIEWER_PROP:
      const _custom_viewer = state.custom_viewer;
      _custom_viewer[action._prop] = action._value;
      return {
        ...state,
        custom_viewer: _custom_viewer,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
      };

    case API_ERROR:
      return {
        ...state,
        loading: false,
        alert: {
          success: false,
          msg: "Api Resource could not be fetched",
        },
      };

    case DONE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function deleteDocument(_document, _token) {
  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/documents/${_document.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json()) // TODO: return this response to show success msg ?
      .then((data) => {
        if (data.message === "OK") {
          dispatch({ type: DELETE_DOCUMENT, id: _document.id });
        } else {
          dispatch({ type: API_ERROR });
        }
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function saveDocument(_data, _token) {
  const url = _data.document.id ? `/${_data.document.slug}` : "";
  const method = _data.document.id ? "PATCH" : "POST";

  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/documents${url}`, {
      method: method,
      body: JSON.stringify(_data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((myJson) => {
        dispatch({ type: SAVE_DOCUMENT, data: myJson });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function getDocument(_id, _token) {
  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/documents/${_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((myJson) => {
        dispatch({ type: GET_DOCUMENT, data: myJson });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function getDocuments(
  _username,
  _token,
  from = 0,
  size = 100,
  keepPrevious,
) {
  return (dispatch) => {
    dispatch({ type: LOADING });
    api.Profile.getDocuments(_username, from, size, _token)
      .then(handleErrors)
      .then((response) => response.json())
      .then((documents) => {
        const hasMore = documents.length > 0 ? true : false;
        dispatch({
          type: keepPrevious === true ? GET_MORE_DOCUMENTS : GET_DOCUMENTS,
          data: { hasMore, items: documents },
        });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}

export function getCollection(_username, _id, _token) {
  return (dispatch) => {
    dispatch({ type: LOADING });
    return fetch(
      `${env.REACT_APP_API_URL}/users/${_username}/collections/${_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${_token}`,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: EDIT_COLLECTION, data: json });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}

export function getCollections(
  _username,
  _token,
  from = 0,
  size = 100,
  keepPrevious,
) {
  // TODO: find out correct way to call backend for pagination (from / size)
  return (dispatch) => {
    dispatch({ type: LOADING });
    api.Profile.getCollections(_username, _token)
      .then(handleErrors)
      .then((response) => response.json())
      .then((collections) => {
        const hasMore = collections.length > 0 ? true : false;
        dispatch({
          type: keepPrevious === true ? GET_MORE_COLLECTIONS : GET_COLLECTIONS,
          data: { hasMore, items: collections },
        });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}

export function deleteCollection(_username, _slug, _token) {
  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/collections/${_slug}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function saveCollection(_username, _data, _token) {
  return (dispatch) => {
    // dispatch({ type: LOADING });
    return fetch(`${env.REACT_APP_API_URL}/collections/create_with_documents`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: DONE });
        return true;
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}
export function addToCollection(_username, slug, documents, _token) {
  return (dispatch) => {
    // dispatch({ type: LOADING });
    return fetch(
      `${env.REACT_APP_API_URL}/users/${_username}/collections/${slug}/documents`,
      {
        method: "POST",
        body: JSON.stringify({ documents }),
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${_token}`,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
      .then(handleErrors)
      .then((json) => {
        dispatch({ type: DONE });
        return true;
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
  };
}

export function getCustomViewer(_username, _token) {
  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/users/${_username}/viewer`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((myJson) => {
        dispatch({ type: CUSTOM_VIEWER, _viewer: myJson });
      })
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function setCustomViewer(_prop, _value) {
  return { type: SET_CUSTOM_VIEWER_PROP, _prop, _value };
}

/*
 http
  .put({
    url: `v1/users/${this.props.loggedUser.username}/viewer`,
    data: {
      topbar_color: this.state.topbar_color,
      bg_color: this.state.bg_color,
      icon_color: this.state.icon_color,
      icon_color_hover: this.state.icon_color_hover,
      arrow_color: this.state.arrow_color,
      arrow_color_hover: this.state.arrow_color_hover,
      related_docs_scope: this.state.related_docs_scope ? 1 : 0,
      link: this.state.link,
      link_target: this.state.link_target
    }
  })
  .done(() => {
    // Saving Logo
    const logoImage = $("#logoInput")[0].files[0];
    // Upload Logo
    if (typeof logoImage !== "undefined") {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "PUT",
        `${config.apiURL}/v1/users/${this.props.loggedUser
          .username}/viewer/logo`,
        true
      );
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${storage.get("apiToken")}`
      );
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // Notification
            window.notificationSystem.addNotification({
              title: "Configuration saved",
              message: "The document settings have been saved!",
              level: "info",
              position: "tr",
              autoDismiss: 4
            });
            this.setState({
              logo: `${config.apiURL}/v1/users/${this.props.loggedUser
                .username}/logo/normal`
            });
            // console.log("Success on logo upload --->", xhr.responseText);
            // console.log("Logo --->", this.state.logo);
          }
        }
      };
      xhr.send(logoImage);
    } else {
      // Notification
      window.notificationSystem.addNotification({
        title: "Configuration saved",
        message: "The document settings have been saved!",
        level: "info",
        position: "tr",
        autoDismiss: 4
      });
    }
  });
*/

export function saveCustomViewer(_username, _data, _token) {
  return (dispatch) =>
    fetch(`${env.REACT_APP_API_URL}/users/${_username}/viewer`, {
      method: "PUT",
      body: JSON.stringify(_data),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${_token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((myJson) => dispatch({ type: CUSTOM_VIEWER, _viewer: myJson }))
      .catch((error) => {
        console.error("Problem with your fetch operation:", error);
        dispatch({ type: API_ERROR });
      });
}

export function selectDocument(_id) {
  return { type: SELECT_DOCUMENT, id: _id };
}

export function selectCollection(_id) {
  return { type: SELECT_COLLECTION, id: _id };
}
